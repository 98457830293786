import { Routes, Route } from 'react-router-dom';
import RequireAuth from './components/RequireAuth.js';
import Map from './components/Map.js'
import Home from './components/Home.js';
import Register from './components/Register.js'
import Login from './components/Login.js';
import Layout from './components/Layout.js';
import Team from './components/teamPage/Team.js';
import UserSettings from './components/UserSettings.js';
import PersistLogin from './components/PersistLogin.js';

function App() {
    return (
        <Routes>
            <Route path='/' element={<Layout />} >
                    <Route element={<PersistLogin />}>
                        <Route index element={<Home/>} />
                        <Route path='/login' element={<Login/>} />
                        <Route path='/register' element={<Register/>} />
                        <Route path='/map' element={<Map />} />
                        <Route element={<RequireAuth />}>
                            {/* put here any routes that are protected by login */}
                            <Route path='/my-team/*' element={<Team/>} />
                            <Route path='/user-settings' element={<UserSettings />} />
                        </Route>
                    </Route>
            </Route>
        </Routes>
    );
}

export default App;
