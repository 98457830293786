import useAuth from "../hooks/useAuth";

const DisplayWithoutAuth = ({children}) => {
    const { auth } = useAuth();

    return (
        <>
            {auth?.accessToken 
            ? undefined
            : children}
        </>
    )
}

export default DisplayWithoutAuth;