export const regex = {
    "full_name": /^[a-zA-Z \-.']+$/,
    "user_name": /^[a-zA-Z0-9_\-]{4,16}$/,
    "email":  /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
    "email_standrews": /^[^@ \t\r\n]+@st-andrews\.ac\.uk$/,
    "phone_number": null,
    "password": /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-]).{8,}$/,
    "team_name": /^[a-zA-Z0-9_\- ]{4,16}$/,
    "post_title": /^.{1,50}$/,
    "post_body": /^.{0,500}$/
}