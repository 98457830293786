import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function ResultScreen({success, statusCode, message, navigate}) {

    return (
        <Container className="justify-content-center">
            <Row>
                <Col className="form-col mt-3" md={{span: 6, offset: 3}}>
                    <h1>{success ? "Success!" : "There was a problem"}</h1>
                    {
                        statusCode
                            ? <h2>Status: {statusCode}</h2>
                            : null
                    }
                    <p>{message}</p>
                    <Link to={navigate.href}>{navigate.text}</Link>
                </Col>
            </Row>
        </Container>
    )
}

export default ResultScreen;