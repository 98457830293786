class Gender {
    static #_MALE = 0;
    static #_FEMALE = 1;
    static #_OTHER = 2;
    static get MALE() { return this.#_MALE; }
    static get FEMALE() { return this.#_FEMALE; }
    static get OTHER() { return this.#_OTHER; }
    static get values() {
        return {
            'Male': this.#_MALE,
            'Female': this.#_FEMALE,
            'Other': this.#_OTHER
        }
    }
    static validate(gender) {
        return [0, 1, 2].includes(gender);
    }
}

export default Gender;