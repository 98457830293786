import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ResultScreen from "../ResultScreen";
import { Button, Form } from "react-bootstrap";

const LEAVE_TEAM_URL = "/teams/leave";

const LeaveTeam = ({setDisplay}) => {

    const { auth } = useAuth();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // post submission
        try {
            const response = await axiosPrivate.post(LEAVE_TEAM_URL);
            setDisplay(<ResultScreen
                success={true}
                statusCode={response.status}
                message={`You have now left ${auth.team.team_name}.`}
                navigate={{href: "/", text: "Take me home!"}}
            />);
        }
        catch (err) {
            console.log(`Error ${err.response.status}: ${err.response?.data}`);
            setDisplay(<ResultScreen
                success={false}
                statusCode={err.response.status}
                message={err.response?.data}
                navigate={{href: "/", text: "Take me home!"}}
            />);
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Button variant="danger" type="submit">
                Leave {auth.team.team_name}
            </Button>
        </Form>
    )
}

export default LeaveTeam;