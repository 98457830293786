import { useEffect, useState } from "react";
import { useMap, Marker, Popup } from "react-leaflet";
import LocationMarkerIcon from "../markerIcons/Location";

function CurrentLocationMarker({setLocation}) {
    
    const [position, setPosition] = useState(null);
    const map = useMap();

    useEffect(() => {
        map.locate().on('locationfound', (e) => {
            setPosition(e.latlng);
            setLocation(e.latlng);
            map.flyTo(e.latlng, map.getZoom());
        })
    }, [map])

    return position === null ? null : (
        <Marker position={position} icon={LocationMarkerIcon}>
            <Popup>
                Your current location!
            </Popup>
        </Marker>
    );
}

export default CurrentLocationMarker;