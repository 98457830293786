import { createContext, useState, useEffect } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [persist, setPersist] = useState(JSON.parse(localStorage.getItem('persist')) || false);

    useEffect(() => {
        localStorage.setItem('persist', persist);
        // console.log(`Persist changed to ${persist}`)
    }, [persist]);

    return (
        <AuthContext.Provider value={{auth, setAuth, persist, setPersist}} >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;