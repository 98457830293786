import GeoPoint from "geopoint";
import { useEffect, useState } from "react";
import '../styles/TeamsProgressBar.scss';
import axios from "../api/axios";
import useConfig from "../hooks/useConfig";

const LATEST_LOCATIONS_URL = '/locations/latest';
const ROUTE_URL = "/locations/route";
const RACE_STATUS_URL = '/config/race-status';

const TeamsProgressBar = () => {

    const {config} = useConfig();
    const [blobs, setBlobs] = useState([]);

    const route = {};

    const getLocations = async () => {
        // get the team locations
        try {
            const response = await axios.get(LATEST_LOCATIONS_URL);
            createBlobs(response.data);
        }
        catch (err) {
            console.log(`Error ${err.response?.status}: ${err.response?.data}`);
            // console.log(err)
        }
    }

    const createBlobs = (locations) => {
        if (!route.start || !route.finish) {
            console.log('No route!')
            return; // cannot compute unless we have start and finish locations
        }
        const blobs = [];
        for (const location of locations) {
            const geo = new GeoPoint(location.latitude, location.longitude);
            const distToStart = geo.distanceTo(route.start);
            const distToFinish = geo.distanceTo(route.finish);
            const ratio = distToStart / (distToStart + distToFinish);
            blobs.push(
                <Blob key={location._id} teamname={location.name} ratio={ratio}></Blob>
            );
        }
        setBlobs(blobs);
    }

    // on config load
    useEffect(() => {
    
        // only continue if status is 'ongoing' or 'finished' ...
        if (config?.race_status?.status > 1 &&
            config?.start?.lat && // and none of lat/lng from start/finish are missing
            config?.start?.lng &&
            config?.finish?.lat &&
            config?.finish?.lng
        ) {
            route.start = new GeoPoint(config.start.lat, config.start.lng);
            route.finish = new GeoPoint(config.finish.lat, config.finish.lng);
            getLocations();
        }
    }, [config])

    return (
        <>
            {
                config?.race_status?.status > 1 ?
                    <div className="tpb">
                        <div className="tpb-line">
                            <span className="tpb-terminal"></span>
                            <span className="tpb-span"></span>
                            <span className="tpb-terminal"></span>
                            {blobs}
                        </div>
                    </div>
                : undefined
            }
        </>
    );
}

const Blob = (props) => {
    
    const size = '1.3rem';
    const barheight = '2rem';
    const terminalWidth = '0.5rem';
    const { teamname, ratio, ...other } = props;

    return (
        <span {...other} className="tpb-blob" style={{
            width: size,
            height: size,
            left: `calc((100% - ${terminalWidth}) * ${ratio} - (${size} / 2) + (${terminalWidth} / 2))`,
            top: `calc((${barheight} - ${size}) / 2)`
        }}>
            <span className="tpb-blob-label">
                {teamname} {Math.round(100 * ratio)}%
            </span>
        </span>
    )
}

export default TeamsProgressBar;