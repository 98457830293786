import '../styles/Footer.css'

function Footer() {
    return (
        <footer>
            <div>
                <ul>
                    <li>Charities Campaign</li>
                    <li>Facebook</li>
                    <li>Snapchat</li>
                    <li>Contact Us</li>
                </ul>
            </div>
            <div>
                <p>Our present sponsers:</p>
                <ul>
                    <li>Morissons</li>
                    <li>Sainsbury's</li>
                </ul>
            </div>
        </footer>
    );
}

export default Footer;