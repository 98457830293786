import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CreateTeam from "./CreateTeam";
import InviteToTeam from "./InviteToTeam";
import Loading from "../Loading";
import LogLocation from "./LogLocation";
import { toBeEmpty } from "@testing-library/jest-dom/dist/matchers";
import ResultScreen from "../ResultScreen";
import useAuth from "../../hooks/useAuth";
import LeaveTeam from "./LeaveTeam";
import TeamMembershipPage from "./TeamMembershipPage";

const GET_MY_TEAM_URL = '/teams/me';
const JOIN_TEAM_URL = '/teams/join';
const JOIN_LINK_RE = /^\/my-team\/join\/.{64}/;
const TOKEN_START = 14;
const TOKEN_END = 79;

const Team = () => {
    
    const axiosPrivate = useAxiosPrivate();
    const [display, setDisplay] = useState(<Loading />);
    const {auth, setAuth} = useAuth();

    const chooseResource = async () => {

        try {
            const response = axiosPrivate.get(GET_MY_TEAM_URL).then((response) => {
                if (response.data.in_team) {
                    setAuth({...auth, team: response.data});
                    // then show invite, team settings etc
                    setDisplay(<>
                        <TeamMembershipPage setDisplay={setDisplay} />
                    </>);
                }
                else {
                    // then show create team, join team etc
                    setDisplay(<CreateTeam setDisplay={setDisplay} />);
                }
            });
        }
        catch (err) {
            console.log(`Error ${err.response.status}: ${err.response?.data}`);
        }
    }

    const checkForJoinLink = async () => {

        const joinLinkUsed = window.location.pathname.search(JOIN_LINK_RE) == 0;

        if (joinLinkUsed) {

            const token = window.location.pathname.substring(TOKEN_START, TOKEN_END);

            try {
                const response = await axiosPrivate.post(
                    JOIN_TEAM_URL,
                    JSON.stringify({
                        token: token
                    }),
                    {}
                );
                console.log(JSON.stringify(response?.data));
                setDisplay(<ResultScreen success={true} statusCode={response.status} message={`You are now a member of ${response?.data?.team_name}.`} navigate={{href: "/", text: "Take me home!"}} />)
            }
            catch (err) {
                console.log(`Error ${err.response.status}: ${err.response?.data}`);
                setDisplay(<ResultScreen success={false} statusCode={err.response.status} message={err.response?.data} navigate={{href: "/", text: "Take me home!"}} />)
            }

            window.history.replaceState(null, null, "/my-team");
        }
        else {
            chooseResource();
        }
    }

    useEffect(() => {
        checkForJoinLink();
    }, []);

    return (display);
}

export default Team;