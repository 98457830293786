import { Icon } from "leaflet";
import locationIcon48 from "../../img/icons8-location-48.png";

const sizeMultiplier = 0.7;
const size = 48;

const LocationMarkerIcon = new Icon({
    iconUrl: locationIcon48, 
    iconRetinaUrl: locationIcon48,
    iconSize: [size * sizeMultiplier, size * sizeMultiplier], 
    iconAnchor: [0.5 * size * sizeMultiplier, size * sizeMultiplier],
    tooltipAnchor: [0, 0]
})

export const locationMarkerAttribution = '<a target="_blank" href="https://icons8.com/icon/13800/location">Location</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>';

export default LocationMarkerIcon;