import { useEffect, useState } from "react";
import FormInput from "../FormInput";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { regex } from "../../constants/regex";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import Team from "./Team";

const CREATE_TEAM_URL = '/teams/create';

function CreateTeam({setDisplay}) {

    const {auth, setAuth} = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [values, setValues] = useState({
        teamname: ''
    })
    const inputs = [
        {
            id: 1,
            name: 'teamname',
            type: 'input',
            placeholder: 'Team Name',
            label: 'Team Name',
            pattern: regex.team_name,
            errorMessage: "Between 4 and 16 alphanumeric characters, '_' and '-'"
        }
    ]

    const handleSubmit = async (e) => {
        setErrorMessage('');
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        // post submission
        try {
            const response = await axiosPrivate.post(
                CREATE_TEAM_URL,
                JSON.stringify({
                    team_name: values.teamname
                })
            );
            setAuth({...auth, team:{team_id: response.data.id, team_name: response.data.name, is_team_admin: 1}});
            setDisplay(<Team setDisplay={setDisplay} />);
        }
        catch (err) {
            console.log(`Error ${err.response.status}: ${err.response?.data}`);
            if (err.response?.data?.reasons) {
                setErrorMessage(err.response.data.reasons.join(' '));
            }
            else {
                setErrorMessage(err.response?.data);
            }
        }
    }

    const onChange = (e) => {
        setValues({...values, [e.target.name]:e.target.value})
    }

    return (
        <Container class="create-team-container">
            <Row>
                <Col></Col>
                <Col md className="create-team-col form-col">
                    <Form onSubmit={handleSubmit} noValidate validated={validated}>
                        <h1>Create Team</h1>
                        {inputs.map((input) => (
                            <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange}/>
                        ))}
                        <Button variant="primary" type="submit">
                            Create
                        </Button>
                        <Alert variant={"danger"} className="error-message mt-3">{errorMessage}</Alert>
                    </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
        // <div>
        //     <form onSubmit={handleSubmit} >
        //         <h1>Create Team</h1>
        //         {inputs.map((input) => (
        //             <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange}/>
        //         ))}
        //         <button>Submit</button>
        //         <span className="error-message">{errorMessage}</span>
        //     </form>
        // </div>
    )
}

export default CreateTeam;