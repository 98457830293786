import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import '../styles/Home.css';
import TeamsProgressBar from './TeamsProgressBar';
import { CircleChevronDown } from 'akar-icons';
import iconSettings from '../constants/iconSettings';
import { Link } from 'react-router-dom';
import solidariteeLogo from '../img/solidaritee-logo.webp';
import richmondsHopeLogo from '../img/richmonds-hope-logo.png';
import alzheimersResearchUkLogo from '../img/alzheimers-research-uk-logo.png';
import DisplayWithAuth from './DisplayWithAuth';
import DisplayWithoutAuth from './DisplayWithoutAuth';
import useAuth from '../hooks/useAuth';
import { useRef } from 'react';
import useConfig from '../hooks/useConfig';
import { ALZHEIMERS_RESEARCH_UK, HOMEPAGE_MEDIA, RICHMONDS_HOPE, SOLIDARITEE } from '../constants/externalURLs';

function Home() {

    const {auth} = useAuth();
    const {config} = useConfig();
    const contentRef = useRef();

    return (
        <>
            {
                config?.race_status?.status > 0 ?
                    <div className='home-media'>
                        <video autoPlay loop>
                            <source src={HOMEPAGE_MEDIA}></source>
                        </video>
                        <button onClick={() => contentRef.current.scrollIntoView({behavior: 'smooth'})}>
                            <CircleChevronDown className='home-media-scroll' color='white' strokeWidth={iconSettings.strokeWidth} size={36} />
                        </button>
                    </div>
                : undefined
            }
            <Container fluid className="home-container" id='content' ref={contentRef}>
                <Row>
                    <TeamsProgressBar />
                </Row>
                <Row md={3} xs={1} className='justify-content-md-center'>
                    <Col md={{span: 3, order: 1}} xs={{order: 2}} className="form-col mt-3 me-3">
                        <h1>Our Charities</h1>
                        <Row md={{cols: 1}} xs={{cols: 3}}>
                            <Col>
                                <a target='_blank' href={SOLIDARITEE}><Image className='charities-logo' src={solidariteeLogo} /></a>
                            </Col>
                            <Col>
                                <a target='_blank' href={RICHMONDS_HOPE}><Image className='charities-logo' src={richmondsHopeLogo} /></a>
                            </Col>
                            <Col>
                                <a target='_blank' href={ALZHEIMERS_RESEARCH_UK}><Image className='charities-logo' src={alzheimersResearchUkLogo} /></a>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={{span: 5, order: 2}} xs={{order: 1}} className='form-col mt-3 me-3'>
                        <h1>About</h1>
                        <p>Welcome to <strong>Race2</strong>, one of St Andrews' most exhilarating and talked-about events! Each year, teams of students sign up to race from Scotland into Europe in a big hitch-hiking adventure — all in the name of charity.</p>
                        <p>Over the years, <strong>Race2</strong> has garnered massive support, raising thousands of pounds for deserving causes while fostering a sense of camaraderie, adventure, and goodwill. Teams of intrepid racers rely solely on their wits, determination, and the kindness of strangers as they navigate their way across Europe, all while making unforgettable memories as they go.</p>
                        <p>As we gear up for another exciting chapter of <strong>Race2</strong>, we invite you to stay tuned and keep a close eye on this space for updates! Whether you're a participant, a sponsor, or simply a supporter, this is where you’ll get the latest news about the race.</p>
                    </Col>
                    <Col md={{span: 3, order: 3}} xs={{order: 3}} className="form-col mt-3">
                        <DisplayWithAuth>
                            <h1>Hello, {auth?.user?.user_name}</h1>
                            <Button className="full-width-button" as={Link} to="/my-team" variant="primary">My Team</Button>
                            <Button className="full-width-button" as={Link} to="/map" variant="primary">Locations</Button>
                        </DisplayWithAuth>
                        <DisplayWithoutAuth>
                            <h1>Get Involved</h1>
                            <Button className="full-width-button" as={Link} to="/register" variant="primary">Register Now!</Button>
                            <Button className="full-width-button" as={Link} to="/login" variant="primary">Login</Button>
                        </DisplayWithoutAuth>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Home;