import axios from "../api/axios";
import useAuth from "./useAuth";

function useRefreshToken() {
    const { auth, setAuth } = useAuth();

    const refresh = async () => {

        const response = await axios.post(
            '/token',
            JSON.stringify({
                refreshToken: auth?.refreshToken
            }),
            {
                withCredentials: true
            }
        );
        const { accessToken, ...user } = response.data;
        setAuth(prev => { return {...prev, user, accessToken }});

        return response.data.accessToken;
    }

    return refresh;
}

export default useRefreshToken;