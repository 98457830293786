import { Alert } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";

const RaceValid = () => {

    const { auth } = useAuth();

    return (
        <>
            <h1>Ready to Race?</h1>
            <Alert variant={auth?.team?.raceValid?.valid ? "success" : "danger"}>Your team is <strong>{auth?.team?.raceValid?.valid ? '' : 'not '}</strong>ready to race.</Alert>
            {
                auth?.team?.raceValid?.reasons?.map(reason => 
                    <Alert variant="secondary" className="mt-3" key={reason}>{reason}</Alert>
                )
            }
        </>
    );
}

export default RaceValid;