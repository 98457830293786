import { RotatingLines } from 'react-loader-spinner';

const Loading = () => {
    return (
        <div className='spinner-loader'>
            <RotatingLines 
                visible={true}
                height="70"
                width="70"
                strokeColor="orange"
            />
        </div>
    );
}

export default Loading;