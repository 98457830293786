import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import Loading from "./Loading";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth, persist, setPersist } = useAuth();

    // runs when the component loads
    useEffect(() => {
        // console.log(persist, auth.accessToken)
        const verifyRefreshToken = async () => {
            try {
                // console.log('Attempting to sign you back in')
                await refresh();
                // console.log('New access token received')
            }
            catch (err) {
                // console.error(err);
                // failed to log in - set persist to false
                setPersist(false);
                // console.log('Failed to sign you back in')
                // console.log(`${err.status}: ${err.message}`)
            }
            finally {
                setIsLoading(false);
            }
        }

        // if we dont have an access token, then check our refresh token
        if (persist && !auth.currentlyAuthenticating) {
            !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
        }
    }, [persist, auth?.accessToken, refresh]);

    return (
        <>
            {
                !persist
                    ? <Outlet />
                    : isLoading
                        ? <Loading />
                        : <Outlet />
            }
        </>
    );
}

export default PersistLogin;