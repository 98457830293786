import { useState } from "react";
import FormInput from "../FormInput";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import copy from "copy-to-clipboard";
import { Copy } from "akar-icons";
import useAuth from "../../hooks/useAuth";
import { Button, Form } from "react-bootstrap";

const INVITE_URL = `${window.location.origin}/my-team/join/`;

const InviteToTeam = () => {

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [values, setValues] = useState({
        max_uses: 1
    })
    const [inviteLink, setInviteLink] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // post submission
        try {
            const response = await axiosPrivate.post(
                "/teams/invite",
                JSON.stringify(values),
                {}
            );
            setInviteLink(INVITE_URL + response?.data?.inviteToken);
        }
        catch (err) {
            console.log(`Error ${err.response.status}: ${err.response?.data}`);
        }
    }

    const onChange = (e) => {
        setValues({...values, [e.target.name]:e.target.value})
    }

    return (
        <Form onSubmit={handleSubmit} >
            <h1>Invite to {auth.team.team_name}</h1>
            <Form.Label>Number of people to invite</Form.Label>
            <Form.Select className="mb-3" name="max_uses" onChange={onChange} value={values.max_uses}>
                <option value="1">One</option>
                <option value="2">Two</option>
            </Form.Select>
            <Button variant="primary" type="submit">
                Create Link
            </Button>
            <span className="form-spacer"></span>
            {
                inviteLink != ''
                    ? <div className="copy-box" onClick={() => {copy(inviteLink);}}><Copy className="icon-top-right" title="copy"/> {inviteLink}</div>
                    : null
            }
        </Form>
    )
}

export default InviteToTeam;