import { createContext, useEffect, useState } from "react";
import axios from "../api/axios";

const CONFIG_URL = '/config';

const ConfigContext = createContext({});

export const ConfigProvider = ({children}) => {
    
    const [config, setConfig] = useState({});

    const getConfig = async () => {
        try {
            const response = await axios.get(CONFIG_URL);
            setConfig(response.data);
        }
        catch (err) {
            console.log('Unable to fetch config: ', err);
        }
    }

    // get the config stuff from the database on load
    useEffect(() => {
        getConfig();
    }, []);

    return (
        <ConfigContext.Provider value={{config}}>
            {children}
        </ConfigContext.Provider>
    );
}

export default ConfigContext;