import axios from "../api/axios";
import useAuth from "./useAuth";

const useLogout = () => {
    const { setAuth, setPersist } = useAuth();

    const logout = async () => {
        setAuth({});
        setPersist(false);
        try {
            const response = await axios.post(
                '/users/logout',
                {
                    withCredentials: true
                }
            );
        }
        catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout;