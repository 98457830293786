import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import FormInput from "./FormInput";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "../api/axios";
import { regex } from "../constants/regex";
import { Col, Container, Form, Row, Button, Alert } from "react-bootstrap";

const LOGIN_URL = '/users/login';

function Login() {

    const { auth, setAuth, persist, setPersist } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    const [validated, setValidated] = useState(false);
    const [persistChecked, setPersistChecked] = useState(persist);
    const [errorMessage, setErrorMessage] = useState('');
    const [values, setValues] = useState({
        user_name: '',
        password: ''
    })
    const inputs = [
        {
            id: 1,
            name: 'user_name',
            type: 'input',
            placeholder: 'Username',
            label: 'Username',
            pattern: regex.user_name,
            errorMessage: 'Please enter a valid username'
        },
        {
            id: 2,
            name: 'password',
            type: 'password',
            placeholder: 'Password',
            label: 'Password',
            pattern: regex.password,
            errorMessage: "At least 8 characters, with an uppercase, lowercase, digit, and special character (#?!@$%^&*-)"
        }
    ]

    const handleSubmit = async (e) => {
        setErrorMessage('');
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        // indicate to other components that we are currently authenticating
        // this is important to prevent unwanted behaviour from PersistLogin
        setAuth({...auth, currentlyAuthenticating: true});

        // set persist
        setPersist(persistChecked);

        // post submission
        try {
            const response = await axios.post(
                LOGIN_URL,
                JSON.stringify({...values, persist: persistChecked}),
                {
                    headers: {'Content-Type': 'application/json'}
                    // withCredentials: true
                }
            );
            const {accessToken, refreshToken, ...user} = response.data;
            setAuth({user, accessToken, refreshToken});
            navigate(from, {replace: true});
        }
        catch (err) {
            console.log(`Error ${err.response.status}: ${err.response?.data}`);
            if (err.response?.data?.reasons) {
                setErrorMessage(err.response.data.reasons.join(' '));
            }
            else {
                setErrorMessage(err.response?.data);
            }
        }
    }

    const onChange = (e) => {
        setValues({...values, [e.target.name]:e.target.value})
    }

    const togglePersist = () => {
        setPersistChecked(prev => !prev);
    }

    return (
        <Container className="login-container">
            <Row className="mt-3">
                <Col></Col>
                <Col md="auto" className="login-col form-col">
                    <Form onSubmit={handleSubmit} validated={validated} noValidate>
                        <h1>Login</h1>
                        {inputs.map((input) => (
                            <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange}/>
                        ))}
                        <Form.Group className="mb-3" controlId="persist">
                            <Form.Check type="checkbox" label="Trust this device" checked={persistChecked} onChange={togglePersist} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                        <Alert variant={"danger"} className="error-message mt-3">{errorMessage}</Alert>
                        <Link to="/register">Don't have an account? Register</Link>
                    </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}

export default Login;