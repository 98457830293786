import { Icon } from "leaflet";
import homeIcon48 from "../../img/icons8-home-address-48.png";

const sizeMultiplier = 0.8;
const size = 48;

const HomeMarkerIcon = new Icon({
    iconUrl: homeIcon48, 
    iconRetinaUrl: homeIcon48,
    iconSize: [size * sizeMultiplier, size * sizeMultiplier], 
    iconAnchor: [0.5 * size * sizeMultiplier, size * sizeMultiplier],
    tooltipAnchor: [0, 0]
})

export const homeMarkerAttribution = '<a target="_blank" href="https://icons8.com/icon/107459/home-address">Home Address</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>';

export default HomeMarkerIcon;