import useAuth from "../hooks/useAuth";

const DisplayWithAuth = ({children}) => {
    const { auth } = useAuth();

    return (
        <>
            {auth?.accessToken 
            ? children
            : undefined}
        </>
    )
}

export default DisplayWithAuth;