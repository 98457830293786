import { Icon } from "leaflet";
import destinationIcon48 from "../../img/icons8-destination-48.png";

const sizeMultiplier = 0.7;
const size = 48;

const DestinationMarkerIcon = new Icon({
    iconUrl: destinationIcon48, 
    iconRetinaUrl: destinationIcon48,
    iconSize: [size * sizeMultiplier, size * sizeMultiplier], 
    iconAnchor: [7 * sizeMultiplier, 44 * sizeMultiplier],
    tooltipAnchor: [0, 0]
})

export const destinationMarkerAttribution = '<a target="_blank" href="https://icons8.com/icon/20844/destination">Destination</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>';

export default DestinationMarkerIcon;