import { Col, Container, Row } from "react-bootstrap"
import InviteToTeam from "./InviteToTeam"
import LeaveTeam from "./LeaveTeam"
import LogLocation from "./LogLocation"
import RaceValid from "./RaceValid"

const TeamMembershipPage = ({setDisplay}) => {

    return (
        <Container className="team-membership-container">
            <Row>
                <Col md className="me-3">
                    <Row className="mt-3">
                        <Col md className="invite-to-team-col form-col"><InviteToTeam /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md className="form-col"><RaceValid /></Col>
                    </Row>
                </Col>
                <Col md className="log-location-col form-col mt-3"><LogLocation setDisplay={setDisplay} /></Col>
            </Row>
            <Row className="mt-3">
                <Col md className="leave-team-col form-col"><LeaveTeam setDisplay={setDisplay} /></Col>
            </Row>
        </Container>
    )
}

export default TeamMembershipPage;